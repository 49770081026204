import React from 'react';

function Header() {
    return (
    <div className="work-title-container">
    <div className="work-title"></div>
    </div>
    )
}

export default Header;
